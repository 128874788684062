import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import noticias07 from "../../../../static/img/blog/noticias-07-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location}
                title='Evolución de la Relación con Inversionistas ante un mercado incremental de inversores retail'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias07}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                05 Nov. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Manuel Pérez
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>Evolución de la Relación con Inversionistas ante un mercado incremental de
                            inversores <i>retail</i></h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>En la actualidad, gran parte de los equipos de Relación con Inversionistas a nivel mundial (RI)
                        se enfocan hacia los inversores
                        institucionales. Sin embargo, los inversores <i>retail</i> han ido posicionándose como una parte
                        importante e influyente en el mundo de
                        las inversiones, originando con ello la necesidad de aplicar nuevas estrategias que le permitan
                        a los equipos de RI lograr un mayor
                        acercamiento con este tipo de inversores, lo que se ha hecho palpable con el surgimiento de
                        varios <i>fintechs</i> y plataformas de <i>trading</i> como Marco Polo. </p>

                    <p>Lo anterior, ha sido impulsado en gran medida por la diminución en costos de admisión y
                        administración de capital de diversos <i>brokers</i> para
                        estimular la atracción de inversiones, de cara al impacto de la pandemia. En este sentido, tan
                        sólo en lo que va del año, casas de bolsa
                        como TD Ameritrade, Charles Schwab, E-Trade y Robinhood en Estados Unidos, registraron la
                        apertura de aproximadamente 608 mil, 609 mil, 326 mil
                        y 3 millones de nuevas cuentas propiedad de inversores <i>retail</i>, respectivamente. Otros
                        ejemplos podrían ser Freetrade, con sede en el
                        Reino Unido, que informó un aumento del 90% en nuevas cuentas desde el mes de marzo; y, la
                        plataforma australiana Stake, que anunció que
                        sus nuevos usuarios se han duplicado. </p>

                    <p>En México, de enero a mayo de 2020, el número de cuentas en casas de bolsa aumentó 65% en
                        comparación con el mismo periodo de 2019,
                        al registrarse 160,074 cuentas adicionales, 1.5 veces más de las que se abrieron en total en los
                        últimos 10 años.</p>

                    <p>En este respecto, la casa de bolsa que más ha crecido en el país durante este periodo es GBM,
                        cuyo número de cuentas aumentó
                        6.8 veces al pasar de 23,947 cuentas en mayo de 2019 a 186,512 en 2020. Con esto, GBM ya tiene
                        más del 40% del número de cuentas en el país. </p>

                    <p>Todo esto, sumado a la amplia oferta de productos existentes, ha propiciado que los
                        inversores <i>retail</i> representen
                        actualmente entre el 20% y el 25% de la actividad diaria del mercado estadounidense, según
                        Citadel Securities, lo que representa,
                        al menos, el doble de su actividad en 2019. Por lo que, de manera colectiva, este tipo de
                        inversionistas puede causar una volatilidad
                        imprevista; ya que suelen tener una visión de corto/mediano plazo y una menor resiliencia ante
                        coyunturas retadoras. Es por ello por lo
                        que resulta fundamental mantener una comunicación eficiente con este grupo de inversores, la
                        cual se puede lograr mediante las siguientes
                        recomendaciones: </p>

                    <ul class="list">
                        <li class="icon-arrows-cw"><b>Participar en foros de inversión o webinars</b>. Al estar presente
                            en estos eventos, es
                            posible mantenerse al día sobre lo que impulsa las decisiones de los inversionistas, lo que
                            podría ser útil para diluir dudas y
                            compartir las perspectivas de las compañías.
                        </li>
                        <li class="icon-arrows-cw"><b>Mantener un acceso fácil y lenguaje sencillo en todos los medios
                            de comunicación.</b> Muchas veces,
                            los inversores <i>retail</i> permanecen con diversas inquietudes que no son resueltas en los
                            informes, comunicados o sitios web de
                            relación con inversionistas por falta de gráficos o uso de términos que no son explicados
                            apropiadamente. Un aspecto fundamental para
                            este punto es lograr mantener los sitios web actualizados con información relevante,
                            haciendo uso de gráficos y una estructura ordenada,
                            pues en su mayoría funge como el primer punto de contacto del inversor con la empresa.
                        </li>
                        <li class="icon-arrows-cw"><b>Proveer información financiera.</b> Ante el alto nerviosismo que
                            suele caracterizar a los
                            inversores <i>retail</i>, la óptima divulgación de información financiera es de suma
                            importancia para darle estabilidad a la
                            cotización del instrumento.
                        </li>
                        <li class="icon-arrows-cw"><b>Establecer políticas para responder adecuadamente a las consultas
                            de RI.</b> Es importante delegar
                            las responsabilidades de comunicación con los inversores, desde la atención a correo
                            electrónico hasta las llamadas telefónicas,
                            procurando en todo momento que cada uno de los departamentos de comunicación se encuentren
                            alineados para evitar crear confusión o
                            duplicar esfuerzos.
                        </li>
                        <li class="icon-arrows-cw"><b>Considerar la posibilidad de ampliar los puntos de contacto de
                            RI.</b> En muchas empresas las
                            relaciones con inversionistas se limitan a la emisión de reportes financieros y al sitio web
                            de RI; por lo que extender la
                            cobertura de la comunicación mediante entrevistas, comunicados de prensa, redes sociales
                            para profesionales como Linkedin,
                            entre otros, impulsará el conocimiento existente en el mercado, principalmente entre
                            inversionistas <i>retail</i>.
                        </li>
                    </ul>

                    <p>Referencias Bibliográficas
                        <ul>
                            <li>Ashwell, B. (2020). How IR should respond to the increase in retail investors. Octubre
                                07, 2020. <a
                                    href="https://www.irmagazine.com/shareholder-targeting-id/how-ir-should-respond-increase-retail-investors"
                                    target="_blank"> IR Magazine</a>.
                            </li>
                            <li>Lara, R. (Septiembre 25, 2020). ¿Qué hiciste en la pandemia? La respuesta de muchas
                                personas: invertir en Bolsa. <a
                                    href="https://expansion.mx/mercados/2020/09/25/que-hiciste-confinamiento-mucha-gente-empezo-invertir-bolsa"
                                    target="_blank"> Expansión</a>.
                            </li>
                            <li>Gilmore, A. (2020). Investor Intelligence: The Rise of Retail. Agosto 26, 2020. <a
                                href="https://www.nasdaq.com/articles/investor-intelligence%3A-the-rise-of-retail-2020-08-26"
                                target="_blank"> Nasdaq</a>.
                            </li>
                            <li>Vallie. M. (2018). The rise of retail investors: How public companies should manage the
                                masses. Febrero 14, 2018. <a
                                    href="https://westwicke.com/2018/02/rise-retail-investors-public-companies-manage-masses/"
                                    target="_blank"> Westwicke</a>.
                            </li>
                        </ul>
                    </p>

                </div>
            </div>
        </Single>
    )
}

export default Detail
